import { login } from "@/api";
let info = localStorage.getItem("userInfo");
var token = info ? JSON.parse(info).access_token : null;
var userId = info ? JSON.parse(info).userId : null;
var userSig = info ? JSON.parse(info).userSig : null;
export default {
    namespaced: true,
    state: {
        token,
        userId,
        userSig,
    },
    mutations: {
        afterLogin(state, data) {
            state.token = data.access_token;
            state.userId = data.userId;
            state.userSig = data.userSig;
            localStorage.setItem("userInfo", JSON.stringify(data));
        },
        afterLogout(state) {
            state.token = "";
            state.userId = "";
            state.userSig = "";
            localStorage.removeItem("userInfo");
        },
    },
    actions: {
        login({ commit, dispatch, state }, data = {}) {
            return new Promise((resolve, reject) => {
                login(data)
                    .then((res) => {
                        commit("afterLogin", res.data);
                        // dispatch('chat/login', {}, { root: true })
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};
