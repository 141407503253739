export default {
    namespaced: true,
    state: {
        userId: "",
        userSig: "",
        token: ""
    },
    mutations: {
        login(state, data) {
            state.userId = data.userId
            state.userSig = data.userSig
            state.token = data.token
        }
    }
}