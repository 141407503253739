import Vue from 'vue'
import Vuex from 'vuex'
import reservation from './modules/reservation'
import profile from './modules/profile'
import user from './modules/user'
import chat from './modules/chat'
import caseDetail from './modules/caseDetail'
import commonQuestions from './modules/commonQuestions'
import doctor from './modules/doctor'

import { camel } from "@/assets/js/varStyle"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loadingList: {},
        sysConfig: {},
        statusBarHeight: 0,
        commonQuestions_display: 0,
        iosVersion: 0,
        isShowLangingPage: "009",
        uniappLogin: false,//当时uniapp gyt登入的时候 需要识别进行特殊操作
        isCanSelectDoctor: true,//判断是否可以点击医生列表进入
        uniappLoginKy: false,//当时uniapp 登入的时候 需要识别进行特殊操作
        uniappLoginYy: false,//当时uniapp 登入的时候 需要识别进行特殊操作
    },
    mutations: {
        setSysConfig(state, data) {
            for (let config of data) {
                state.sysConfig[config.name] = config.value
            }
            state.sysConfig = camel(state.sysConfig)
        }
    },
    modules: {
        reservation,
        profile,
        user,
        chat,
        caseDetail,
        commonQuestions,
        doctor
    }
})
