import TIM from 'tim-js-sdk-ws'
// import COSSDK from 'cos-js-sdk-v5'
import TIMUploadPlugin from 'tim-upload-plugin';

export default class {
  tim = null

  constructor() {
    // SDK是否准备好
    this.ready = false

    // 创建 SDK 实例
    const tim = TIM.create({
      SDKAppID: parseInt(process.env.VUE_APP_SDK_APP_ID)
    })

    // 注册 COS SDK 插件
    tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })

    // 設置日誌級別
    tim.setLogLevel(4)

    // 注册腾讯云即时通信 IM 上传插件
    // tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});

    // 接收谁的消息，空数组的谁的消息都不收
    this.receivedAccount = []

    // 收到消息执行
    tim.on(TIM.EVENT.MESSAGE_RECEIVED, event => {
      if (this.onMessageReceived && typeof this.onMessageReceived === 'function') {

        event.data.forEach(message => {
          const from = message.from
          this.onMessageReceived(message)
          // if (this.receivedAccount.includes(from)) {

          // }
        })
      }
    })

    // 收到会话列表更新执行
    tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, event => {
      if (this.onConversationListUpdate && typeof this.onConversationListUpdate === 'function') {
        this.onConversationListUpdate(event.data)
      }
    })

    // SDK Ready
    tim.on(TIM.EVENT.SDK_READY, event => {
      console.log('SDK Ready')
      this.ready = true
      if (this.onReady && typeof this.onReady === 'function') {
        this.onReady(event)
      }
    })

    //  被T下线
    tim.on(TIM.EVENT.KICKED_OUT, event => {
      if (this.onKickedOut && typeof this.onKickedOut === 'function') {
        this.onKickedOut(event)
      }
    })

    tim.on(TIM.EVENT.ERROR, event => {
      console.log(event)
    })

    tim.on(TIM.EVENT.SDK_NOT_READY, event => {
      this.ready = false
      console.log('SDK NOT Ready')
    })

    this.tim = tim

  }

  /**
   * 發送消息
   * @param {number} type 消息類型 1：文本、2：圖片、3：文件
   */
  sendMessage({ to, type, text, picker, onProgress }) {
    return new Promise((resolve, reject) => {
      let options = {
        to: String(to),
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {},
        onProgress
      }
      let messageInstance
      if (type === 1) {
        options.payload['text'] = text
        messageInstance = this.tim.createTextMessage(options)
      } else if (type === 2) {
        if (onProgress && typeof onProgress === 'function') options.onProgress = onProgress
        options.payload['file'] = picker
        messageInstance = this.tim.createImageMessage(options)
      } else if (type === 3) {
        if (onProgress && typeof onProgress === 'function') options.onProgress = onProgress
        options.payload['file'] = picker
        messageInstance = this.tim.createFileMessage(options)
      }
      this.tim.sendMessage(messageInstance).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   * 获取消息列表
   */
  getMessageList(id, nextReqMessageID = '') {
    return new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error('missing params id'))
        return false
      }
      const conversationID = `${TIM.TYPES.CONV_GROUP}${id}`
      let options = {
        conversationID,
        count: 15
      }
      if (nextReqMessageID) options['nextReqMessageID'] = nextReqMessageID
      this.tim.getMessageList(options).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   * 会话上报已读
   */
  setMessageRead(id) {
    return new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error('missing params id'))
        return false
      }
      const conversationID = `${TIM.TYPES.CONV_GROUP}${id}`
      this.tim.setMessageRead({ conversationID }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   *
   * 获取会话列表
   */
  getConversationList() {
    return new Promise((resolve, reject) => {
      this.tim.getConversationList().then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   *  登錄
   */
  login({ userID, userSig }) {
    return new Promise((resolve, reject) => {
      if (!userID) {
        reject(new Error('missing params userID'))
        return false
      }
      if (!userSig) {
        reject(new Error('missing params userSig'))
        return false
      }
      this.tim.login({ userID, userSig }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   *  登出
   */
  logout() {
    return new Promise((resolve, reject) => {
      if (this.ready) {
        this.tim.logout().then(res => {
          this.receivedAccount = []
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      } else {
        resolve()
      }
    })
  }

  /**
   * TIM 消息实例转换成列表需要的消息
   */
  messageInstanceToMyMessage(instance) {
    let result
    switch (instance.type) {
      case TIM.TYPES.MSG_TEXT:
        result = {
          type: 1,
          isMyself: instance.flow === 'out',
          text: instance.payload.text,
          error: false,
          loading: false
        }
        break
      case TIM.TYPES.MSG_IMAGE:
        var image = instance.payload.imageInfoArray.find(e => e.sizeType === 1)
        var miniImage = instance.payload.imageInfoArray[0]
        result = {
          type: 2,
          isMyself: instance.flow === 'out',
          image: image.url,
          miniImage: miniImage.url,
          error: false,
          loading: false
        }
        break
      case TIM.TYPES.MSG_FILE:
        result = {
          type: 3,
          isMyself: instance.flow === 'out',
          error: false,
          loading: false,
          file: {
            name: instance.payload.fileName,
            size: instance.payload.fileSize,
            url: instance.payload.fileUrl
          }
        }
        break
    }
    return result
  }

  /**
   * 历史消息转换成列表需要的消息
   */
  recordInstanceToMyMessage(instance, userID, otherID) {
    let result
    const isMyself = instance.from === userID
    const content = instance._elements[0]
    switch (content.type) {
      case TIM.TYPES.MSG_TEXT:
        result = {
          type: 1,
          isMyself,
          text: content.content.text,
          error: false,
          loading: false
        }
        break
      case TIM.TYPES.MSG_IMAGE:
        var image = content.content.imageInfoArray.find(e => e.type === 1)
        var miniImage = content.content.imageInfoArray[0]
        result = {
          type: 2,
          isMyself,
          image: image.url,
          miniImage: miniImage.url,
          error: false,
          loading: false
        }
        break
      case TIM.TYPES.MSG_FILE:
        result = {
          type: 3,
          isMyself,
          error: false,
          loading: false,
          file: {
            name: content.content.fileName,
            size: content.content.fileSize,
            url: content.content.fileUrl
          }
        }
        break
    }
    return result
  }

  /**
  * 接口历史消息转换成列表需要的消息
  */
  recordToMyMessage(instance, userID) {
    let result
    const isMyself = instance.From_Account === userID
    const content = instance.MsgBody[0]
    switch (content.MsgType) {
      case TIM.TYPES.MSG_TEXT:
        result = {
          type: 1,
          isMyself,
          text: content.MsgContent.Text,
          error: false,
          loading: false
        }
        break
      case TIM.TYPES.MSG_IMAGE:
        var image = content.MsgContent.ImageInfoArray.find(e => e.Type === 1)
        var miniImage = content.MsgContent.ImageInfoArray[0]
        result = {
          type: 2,
          isMyself,
          image: image.URL,
          miniImage: miniImage.URL,
          error: false,
          loading: false
        }
        break
      case TIM.TYPES.MSG_FILE:
        result = {
          type: 3,
          isMyself,
          error: false,
          loading: false,
          file: {
            name: content.MsgContent.FileName,
            size: content.MsgContent.FileSize,
            url: content.MsgContent.Url
          }
        }
        break
    }
    return result
  }


}
