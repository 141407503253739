export default {
    namespaced: true,
    state: {
        caseData: {
            id: "",
            type: "video",
            name: "",
            gender: "",
            age: "",
            birthday: "",
            phone: "",
            remark: "",
            doctorReportAttachmentList: [],
            relatedFilesList: [],
            attachmentList: [],
            appointmentTime: "",
            appointmentDate: "",
            patientSymptoms: "",
            treatmentPlan: "",
            doctorDiagnosis: "",
            dateList: {},
            status: 0,
            pickupNumber: "",
            billsName: "",
            bills: "",
            clinicId: -1,
            isRefundable: 0,
            //下面是價錢相關數據
            consultationFee: 0.00,//診金 診所應收金額
            freightFee: 0.00,//運費
            transactionFee: 0.00,//手續費
            totalAmount: 0.00,//客戶賬單總額
            platformUsageFee: 0.00,//平台使用費用
            clinicIncomeAmount: 0.00,//診所實際收費金額
            bookingAmount: 0.00,//"預約費用"
            fundingAmount: 0.00,//"補貼"
            medicineFee: 0.00,//藥費
            promoCodeAmount: 0.00,//優惠金額
        },
        billData: {
            id: "",
            amount: "",
            orderDetail: [],
            orderNumber: "",
        }
    },
    mutations: {
        setCaseData: function (state, data) {
            for (let key of Object.keys(state.caseData)) {
                if (data[key] != undefined) {
                    state.caseData[key] = data[key]
                }
            }
        },
        setBillData: function (state, data) {
            for (let key of Object.keys(state.billData)) {
                if (data[key] != undefined) {
                    state.billData[key] = data[key]
                }
            }
        }
    }
}