import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// element-ui相關引入
import '@/plugins/ElementUI'
import './assets/style/theme/index.css'
// iconfont引入
import './assets/icon/iconfont.css'
// progressBar需要使用以下組件
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
// 时间处理函数引入
import timeProcessing from './assets/js/timeProcessing'
// 驼峰/下划线 风格变量转换
import { camel, underline } from './assets/js/varStyle'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.prototype.$base64 = require('js-base64').Base64
Vue.prototype.$md5 = require('md5')
Vue.prototype.$time = timeProcessing
Vue.prototype.$camel = camel
Vue.prototype.$underline = underline

Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
