import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";
import { isSafari } from "@/assets/js";
Vue.use(VueRouter);

const router = new VueRouter({
    routes: routes,
    mode: "history",
});

router.beforeEach((to, from, next) => {
    let whiteList = ["login", "doctorVideoClinic", "doctorMessageClinic", "userVideoClinic"];
    // 未登錄的用戶嘗試通過url訪問時直接返回到登陸頁面
    if (to.query.statusBarHeight) {
        store.state.statusBarHeight = to.query.statusBarHeight;
        sessionStorage.setItem('statusBarHeight', to.query.statusBarHeight)
    } else if (sessionStorage.getItem('statusBarHeight')) {
        store.state.statusBarHeight = sessionStorage.getItem('statusBarHeight');
    }


    if (to.query.version) {
        store.state.iosVersion = to.query.version;
        sessionStorage.setItem('version', to.query.version)
    } else if (sessionStorage.getItem('version')) {
        store.state.iosVersion = sessionStorage.getItem('version');
    }
    let isSa = isSafari();

    if (!store.state.user.token && whiteList.indexOf(to.name) === -1) {
        // 如果是訪問（首頁/預約頁面/預約完成頁面）則放行
        if (["index", "reservation", "reservationComplete"].includes(to.name)) {
            next();
        } else if (whiteList.indexOf(from.name) !== -1) {
            next(false);
        } else {
            window.uni.postMessage({
                data: {
                    action: "update_page",
                },
            });
            if (window.webBackToAndroid) {
                window.webBackToAndroid.onBack();
            }
            if (window.webkit) {
                window.webkit.messageHandlers.getDataFromWeb.postMessage("close");
            }

            if (isSa) {
                window.location.href = "iMeddy://com.imeddy.client?close=1";
            }
        }
    } else {
        next();
    }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => err);
};

export default router;
