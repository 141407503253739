export default {
    timeTo12HrsFormat(time) {
        let hour = time.split(":")[0];
        let minute = time.split(":")[1];
        if (hour == 12) {
            return '下午' + hour + ':' + minute;
        }
        return hour > 12
            ? ` 下午${("0" + (hour - 12)).slice(
                -2
            )}:${minute}`
            : ` 上午${("0" + hour).slice(-2)}:${minute}`;
    },
    dateInHyphenTodateInSlash(dateInHyphen) {
        return dateInHyphen.split("-").reverse().join("/")
    },
    dateInHyphenToChineseDate(dateInHyphen) {
        return dateInHyphen.replace("-", "年").replace("-", "月") + "日"
    },
    createdAtToFullDate(createdAt) {
        let date = this.dateInHyphenTodateInSlash(createdAt.split(" ")[0])
        let time = this.timeTo12HrsFormat(createdAt.split(" ")[1])
        return `${date} ${time}`;
    },
    appointmentToFullDate(appointmentDate, appointmentTime) {
        let date = this.dateInHyphenTodateInSlash(appointmentDate)
        let time = this.timeTo12HrsFormat(appointmentTime.split("-")[0])
        return `${date} ${time}`
    },
    appointmentToSeperatedDate(appointmentDate, appointmentTime) {
        let date = this.dateInHyphenToChineseDate(appointmentDate)
        let time = appointmentTime.split("-")[0]
        return { date, time }
    },
    createdAtToSeperatedDate(createdAt) {
        let date = this.dateInHyphenToChineseDate(createdAt.split(" ")[0])
        let time = createdAt.split(" ")[1].split(":").slice(0, 2).join(":")
        return { date, time }
    }
}