import Vue from 'vue'
import Store from '@/store'
import { doesFileExist, deleteFile } from '@/api'
import { Date } from 'core-js'

// 邮箱验证
export const regEmail = (email) => {
    /* eslint-disable */
    // let reg = /^([a-zA-Z]|[0-9])(\w|)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
    // let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    let reg = /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$/
    if (reg.test(email)) {
        return true
    } else {
        return false
    }
}

// 提示
export const messageTips = (str, type) => {
    Vue.prototype.$message({
        message: str,
        type: type || 'success'
    })
}

// 获取系统参数
export const getConfig = (name, val) => {
    let configs = Store.state.system.configs
    if (!name || !val) {
        return configs
    }
    for (var i = 0; i < configs.length; i++) {
        if (configs[i][name] === val) {
            return configs[i]
        }
    }
    // return ''
}

// 预约时间切分数组
export const CuttingTime = (TimeList, intervalTime) => {
    try {
        let arr = new Array()
        if (Number(intervalTime) <= 0) {
            return arr
        }
        TimeList.forEach(item => {
            let startTime = item.available_start_time
            let endTime = item.available_end_time
            let start_hour = Number(startTime.split(':')[0])
            let start_minute = Number(startTime.split(':')[1])
            let end_hour = Number(endTime.split(':')[0])
            let end_minute = Number(endTime.split(':')[1])
            intervalTime = Number(intervalTime)
            let length = Math.floor(((end_hour - start_hour) * 60 + end_minute - start_minute) / intervalTime)
            let hour = start_hour
            let minute = start_minute
            for (var i = 0; i < length; i++) {
                let start = formatTime(hour, minute)
                if (minute + intervalTime >= 60) {
                    hour++
                    minute = minute + intervalTime - 60
                } else {
                    minute = minute + intervalTime
                }
                arr.push(start + '-' + formatTime(hour, minute))
            }
        })
        return arr
    } catch {
        return []
    }

}

// 刪除文件
export const deletefile = (filePath) => {
    let data = {
        filePath: filePath
    }
    return new Promise((resolve, reject) => {
        doesFileExist(data).then(res => {
            if (res.data.exist) {
                return deleteFile()
            } else {
                reject()
            }
        }).then(res => {
            console.log(res)
            resolve()
        }, error => {
            reject()
        })
    })
}

// 格式化树状表后台数据
export const treeList = (data) => {
    let arr = []
    try {
        data.forEach(item => {

            if (item.folder) {
                let obj = {}
                obj.title = item.folder
                obj.attachment = item.attachment
                arr.push(obj)
            } else {
                item.attachment.forEach(items => {
                    arr.push(items)
                })
            }
        })
        return arr
    } catch {
        () => {
            return ''
        }
    }


}


// 获取文件类型
export const getFileType = file => {
    const type = file.type
    let detail = type
    const name = file.name
    const nameSplit = name.split('.')
    if (nameSplit.length) {
        detail = nameSplit[nameSplit.length - 1]
    }
    if (type.includes('image')) {
        return { type: 'image', detail }
    } else {
        return { type: 'file', detail }
    }
}


// 創建文件临时url (记得回收，不然占用内存)
export const createFileURL = image => {
    let url = ''
    if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(image)
    } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(image)
    } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(image)
    }
    return url
}

// 回收文件临时url
export const revokeFileURL = url => {
    if (window.createObjectURL !== undefined) {
        window.revokeObjectURL(url)
    } else if (window.URL !== undefined) {
        window.URL.revokeObjectURL(url)
    } else if (window.webkitURL !== undefined) {
        window.webkitURL.revokeObjectURL(url)
    }
}

// 等待图片加载完成
export const loadImage = src => new Promise((resolve, reject) => {
    let img = document.createElement('img')
    img.src = src
    img.onload = event => {
        resolve(event)
        img = null
    }
    img.onerror = err => {
        reject(err)
        img = null
    }
})


// 问诊室前後48小時判定
export const clinicOpen = (date, time) => {
    date = date.split('-')
    time = time.split(':')
    let Dates = Math.floor(new Date(date[0], Number(date[1] - 1), date[2], time[0], time[1], '00').getTime() / 1000)
    let now = Math.floor(new Date().getTime() / 1000)
    if (Math.abs(Dates - now) > 48 * 60 * 60) return false
    return true
}

// 是否開啓視頻通訊
export const openVideo = (date, startTime, endTime) => {
    date = date.split('-')
    startTime = startTime.split(':')
    endTime = endTime.split(':')
    let startDate = Math.floor(new Date(date[0], Number(date[1] - 1), date[2], startTime[0], startTime[1], '00').getTime() / 1000)
    let endDate = Math.floor(new Date(date[0], Number(date[1] - 1), date[2], endTime[0], endTime[1], '00').getTime() / 1000)
    let nowDate = Math.floor(new Date().getTime() / 1000)
    let data = {
        isOpen: false,             // 是否开启
        isEnd: false,              // 是否结束
        isNotStart: false,         // 是否未开始
        endTime_count: null,       // 结束倒计时
        startTime_count: null      // 开启倒计时
    }
    // 已經開始
    if (startDate < nowDate) {
        // 進行中
        if (endDate > nowDate) {
            data.isOpen = true
            data.endTime_count = endDate - nowDate
            return data
        } else {
            // 已經結束
            data.isEnd = true
            return data
        }
    } else {
        // 未開始
        data.isNotStart = true
        data.startTime_count = startDate - nowDate
        data.endTime_count = endDate - startDate
        return data
    }
}


// 判断是否手机
export const _isMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}

// URL轉file path
export const toFilePath = (url) => {
    return `uploads/${url.split("uploads/")[1]}`
}
// 判断是否是Safari
export const isSafari = () => {
    console.log(navigator.userAgent);
    let flag = navigator.userAgent.indexOf("Safari") >= 0;
    return flag;
}