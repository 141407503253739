import ElementUI from "element-ui"
import { uploadFile } from "@/api";
// 全局修改默認的ElementUI Upload組件的配置
ElementUI.Upload.props.action.required = false;
ElementUI.Upload.props.action.default = "";
ElementUI.Upload.props.showFileList.default = false;
ElementUI.Upload.props.httpRequest = {
    type: Function,
    default: function (option) {
        let data = new FormData();
        data.append("file", option.file);
        uploadFile(data, (event) => {
            option.onProgress(event)
        })
            .then((res) => {
                option.onSuccess(res)
            })
            .catch((error) => {
                option.onError(error)
            });
    }
}