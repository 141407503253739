import axios from './axios'

// 确认取药
export const patientSign = (data) => axios({
    url: 'patient-cases/sign',
    method: 'get',
    params: data
    // params: {
    //     access_token: access_token,
    //     id: id
    // }
})

// 注册
export const register = (data) => axios({
    url: 'users/register',
    method: 'post',
    data: data
})

// 发送邮件验证码
export const sendcode = (data) => axios({
    url: 'email/send-code',
    method: 'post',
    data: data
})

// 忘记密码
export const forget = (data) => axios({
    url: 'users/forget',
    method: 'post',
    data: data
})

// 用户退出登陆
export const logout = (data) => axios({
    url: 'users/logout',
    method: 'get',
    params: data || {}
})

// 登陆
export const login = (data) => axios({
    url: 'users/login',
    method: 'post',
    data: data
})

// 修改密码
export const changePassword = (data) => axios({
    url: 'users/change-password',
    method: 'post',
    data: data
})

// 个人资料-创建
export const createPersonals = (data) => axios({
    url: 'patient-personals',
    method: 'post',
    data: data
})

// 个人资料-修改
export const updatePersonals = (id, data) => axios({
    url: 'patient-personals/' + id,
    method: 'put',
    params: data || {}
})

// 获取保存最后的个人资料
export const lastPersonals = (data) => axios({
    url: 'patient-personals/last',
    method: 'get',
    params: data || {}
})

// 个人银行卡资料-创建
export const createBankcard = (data) => axios({
    url: 'patient-bank-cards',
    method: 'post',
    data: data
})

// 个人银行卡资料-列表
export const bankcardList = (data) => axios({
    url: 'patient-bank-cards',
    method: 'get',
    params: data || {}
})

// 個人銀行卡資料-刪除
export const deleteBankcard = (id, data) => axios({
    url: 'patient-bank-cards/' + id,
    method: 'delete',
    params: data || {}
})

// 銀行卡類型列表
export const sysBankcardTypes = (data) => axios({
    url: 'sys-bank-card-types',
    method: 'get',
    params: data || {}
})

// 病人記錄-刪除
export const delPatientRecord = (id) => axios({
    url: 'patient-records/' + id,
    method: 'delete'
})

// 病人記錄-明細
export const getPatientRecord = (id) => axios({
    url: 'patient-records/' + id,
    method: 'get'
})

// 病人記錄-列表
export const getPatientRecords = () => axios({
    url: 'patient-records',
    method: 'get'
})

// 病人記錄-修改
export const updPatientRecord = (id, data) => axios({
    url: 'patient-records/' + id,
    method: 'put',
    params: data
})

// 病人記錄-創建
export const newPatientRecord = (data) => axios({
    url: 'patient-records',
    method: 'post',
    params: data
})

// 诊所列表
export const clinicList = (data) => axios({
    url: 'clinics',
    method: 'get',
    params: data || {}
})

// 診所明細
export const clinics = (id, data) => axios({
    url: 'clinics/' + id,
    method: 'get',
    params: data || {}
})

// 医生列表
export const doctorList = (data) => axios({
    url: 'doctors',
    method: 'get',
    params: data || {}
})
// 醫生明細
export const doctors = (id, data) => axios({
    url: 'doctors/' + id,
    method: 'get',
    params: data || {}
})

// 常见问题列表
export const sysFaqsList = (data) => axios({
    url: 'sys-faqs',
    method: 'get',
    params: data || {}
})

// 广告图列表
export const sysAdvertisings = (data) => axios({
    url: 'sys-advertisings',
    method: 'get',
    params: data || {}
})

// 症狀列表
export const sysSymptoms = (data) => axios({
    url: 'sys-symptoms',
    method: 'get',
    params: data || {}
})

// 發病時間
export const sysDiseaseTimes = (data) => axios({
    url: 'sys-disease-times',
    method: 'get',
    params: data || {}
})

// 药物敏感问题列表
export const sysDrugSensitivities = (data) => axios({
    url: 'sys-drug-sensitivities',
    method: 'get',
    params: data || {}
})

// 系統參數列表
export const sysConfigs = (data) => axios({
    url: 'sys-configs',
    method: 'get',
    params: data || {}
})

// 上傳文件
export const uploadFile = (data, onUploadProgress) => axios({
    url: 'files/upload-file',
    method: 'post',
    data: data,
    onUploadProgress
})

// 刪除文件
export const deleteFile = (data) => axios({
    url: 'files/delete-file',
    method: 'post',
    data: data
})

// 判斷文件是否存在
export const doesFileExist = (data) => axios({
    url: 'files/does-file-exist',
    method: 'get',
    params: data || {}
})

// 创建预约
export const patientCases = (data) => axios({
    url: 'patient-cases',
    method: 'post',
    data: data
})

// 取消预约
export const cancelCases = (data) => axios({
    url: 'patient-cases/cancel',
    method: 'get',
    params: data || {}
})

// 创建订单cancel
export const createOrders = (data) => axios({
    url: 'orders',
    method: 'post',
    data: data
})

// 订单支付
export const OrdersPayment = (data) => axios({
    url: 'orders/payment',
    method: 'post',
    data: data
})

// 订单支付_new
export const OrdersPaymentNew = (data) => axios({
    url: 'orders/payment-new',
    method: 'post',
    data: data
})

// 案件列表-    等同於記錄
export const casesList = (data) => axios({
    url: 'patient-cases',
    method: 'get',
    params: data || {}
})

// 案件明细-   等用于案件详情
export const casesDetail = (id, data) => axios({
    url: 'patient-cases/' + id,
    method: 'get',
    params: data || {}
})

// 修改案件
export const updCases = (id, data) => axios({
    url: 'patient-cases/' + id,
    method: 'put',
    params: data || {}
})
// 已经查阅了版本更新提示 进行callback返回api
export const patientsCallBack = (id, data) => axios({
    url: 'patients/' + id,
    method: 'put',
    params: data || {}
})

// 删除案件
export const deleteCases = (id, data) => axios({
    url: 'patient-cases/' + id,
    method: 'delete',
    params: data || {}
})

// 藥品簽收
export const sign = (data) => axios({
    url: 'patient-cases/sign',
    method: 'get',
    params: data || {}
})

// 病人首页
export const patientsHome = (data) => axios({
    url: 'patients/home',
    method: 'get',
    params: data || {}
})

// 轮询病人首页
export const poolingPatientsHome = (data) => axios({
    url: 'patients/home',
    method: 'get',
    params: data || {},
    isPooling: true
})

// 問診室首頁
export const patientsChatroomHome = (data) => axios({
    url: 'patients/chatroom-home',
    method: 'get',
    params: data || {}
})

// 问诊室明细-room_number
export const chatroomsDetail = (data) => axios({
    url: 'chatrooms/detail',
    method: 'get',
    params: data || {}
})

// IM聊天消息列表
export const chatroomMessages = (data) => axios({
    url: 'chatroom-messages',
    method: 'get',
    params: data || {}
})

// 修改用户推送token
export const deviceToken = (data) => axios({
    url: 'users/device-token',
    method: 'post',
    data: data
})

// 问诊室登陆
export const chatroomslogin = (data) => axios({
    url: 'chatrooms/login',
    method: 'post',
    data: data
})

// 优惠码校验
export const promoCodeCheck = (data) => axios({
    url: 'promo-codes/check',
    method: 'post',
    data: data
})

// 案件收据(账单)-列表
export const getPatientCaseReceipts = (data) => axios({
    url: 'patient-case-receipts',
    method: 'get',
    params: data
})

// 订单申请退款
export const applyRefund = (data) => axios({
    url: 'orders/refund',
    method: 'post',
    data
})

// 系统-区域列表
export const areaList = data => axios({
    url: 'sys-areas',
    method: 'get',
    params: data || {}
})

// 系统-支付类型-列表
export const paymentTypes = data => axios({
    url: 'sys-payment-types',
    method: 'get',
    params: data || {}
})

// 会员登录
export const memberLogin = data => axios({
    url: 'imeddy-api-members/login',
    method: 'post',
    data
})

// 查询预约时间是否可用
export const availableTimeQuery = data => axios({
    url: 'patient-cases/appointment-time-log',
    method: 'post',
    data
})

// 病人病歷資料
export const medicalRecord = data => axios({
    url: "patient-drug-sensitivity-replys/reply",
    method: 'get',
    params: data || {}
})
// 病人病歷資料
export const getIdCardInfo = data => axios({
    url: "ocr/id-card",
    method: 'post',
    data: data || {}
})


