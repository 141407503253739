import TIM from "@/plugins/TIM";
import TRTC from "@/plugins/TRTC";

export default {
    namespaced: true,
    state: {
        tim: new TIM(),
        isReady: false,
        rtc: new TRTC(),
        isVideoClosed: false,
        isChatroomClosed: false,
    },
    mutations: {
        setSDKReady(state) {
            state.isReady = true;
        },
        videoClose(state) {
            state.isVideoClosed = true;
        },
        chatroomClose(state) {
            state.isChatroomClosed = true;
        },
    },
};
