import TRTC from 'trtc-js-sdk'

TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.NONE)

export default class {
  /**
   * client 实例
   */
  client = null

  /**
   * 本地 stream 实例
   */
  localStream = null

  /**
   * 远端 stream 实例
   */
  remoteStream = null

  /**
   * 是否支持WebRTC
   */
  support = null

  /**
   * 是否拥有camera
   */
  camera = null

  /**
   * 订阅是否成功
  */
  subscribed = null

  /**
   * 检测是否支持WebRTC
   */
  detectRTC() {
    return new Promise(resolve => {
      TRTC.checkSystemRequirements().then((result) => {
        if (!result.result) {
          this.support = false
          resolve(false)
        } else {
          this.support = true
          resolve(true)
        }
      }).catch(() => {
        resolve(false)
      })
    })
  }

  /**
   * 创建 CLient 对象
   */
  createClient({ userId, userSig }) {
    const options = {
      enableAutoPlayDialog: false,
      mode: 'rtc',
      sdkAppId: 1400570547,
      userId,
      userSig
    }
    this.client = TRTC.createClient(options)
    return this.client
  }

  /**
   * 监听事件
   */
  on(name, callback) {
    this.client.on(name, callback)
  }

  /**
   * 获取本地视频流
   */
  getLocalStream({ userId }) {
    return new Promise((resolve, reject) => {
      const localStream = TRTC.createStream({ userId, audio: true, video: true })

      // 仅采集屏幕视频流
      // const shareStream = TRTC.createStream({ audio: false, screen: true, userId });
      // shareStream.initialize().then(() => {
      //   this.camera = true
      //   this.localStream = localStream
      //   resolve(localStream)
      // }).catch(error => {
      //   // 当屏幕分享流初始化失败时, 提醒用户并停止后续进房发布流程
      //   switch (error.name) {
      //     case 'NotReadableError':
      //       // 提醒用户确保系统允许当前浏览器获取屏幕内容
      //       return;
      //     case 'NotAllowedError':
      //       if (error.message.includes('Permission denied by system')) {
      //         // 提醒用户确保系统允许当前浏览器获取屏幕内容
      //       } else {
      //         // 用户拒绝/取消屏幕分享
      //       }
      //       return;
      //     default:
      //       // 初始化屏幕分享流时遇到了未知错误，提醒用户重试
      //       return;
      //   }
      // })
      localStream.setVideoProfile('1080p');
      localStream.setVideoProfile({ width: 1920, height: 1080, frameRate: 15, bitrate: 1500 /* kpbs */ });
      localStream.initialize().then(() => {
        this.camera = true
        this.localStream = localStream
        resolve(localStream)
      }).catch(err => {
        this.camera = false
        reject(err)
      })
    })
  }

  /**
   * 获取本地音频流
   */
  getAudioLocalStream({ userId }) {
    return new Promise((resolve, reject) => {
      const localStream = TRTC.createStream({ userId, audio: true, video: false })

      // 仅采集屏幕视频流
      // const shareStream = TRTC.createStream({ audio: false, screen: true, userId });
      // shareStream.initialize().then(() => {
      //   this.camera = true
      //   this.localStream = localStream
      //   resolve(localStream)
      // }).catch(error => {
      //   // 当屏幕分享流初始化失败时, 提醒用户并停止后续进房发布流程
      //   switch (error.name) {
      //     case 'NotReadableError':
      //       // 提醒用户确保系统允许当前浏览器获取屏幕内容
      //       return;
      //     case 'NotAllowedError':
      //       if (error.message.includes('Permission denied by system')) {
      //         // 提醒用户确保系统允许当前浏览器获取屏幕内容
      //       } else {
      //         // 用户拒绝/取消屏幕分享
      //       }
      //       return;
      //     default:
      //       // 初始化屏幕分享流时遇到了未知错误，提醒用户重试
      //       return;
      //   }
      // })

      // const shareClient = TRTC.createClient({
      //   mode: 'rtc',
      //   sdkAppId:1400570547,
      //   userId, // 例如：‘share_teacher’
      //   userSig
      // });
      // // 客户端对象进入房间
      // try {
      //   await shareClient.join({ roomId });
      //   // ShareClient join room success
      // } catch (error) {
      //   // ShareClient join room failed
      // }
      // try {
      //   await shareClient.publish(shareStream);
      // } catch (error) {
      // }

      localStream.initialize().then(() => {

        // this.client.publish(localStream).then(() => {
        //   console.log('local stream published');
        // });
        resolve(localStream)
      }).catch(err => {
        resolve(false)
      })
    })
  }

  /**
   *  发布本地视频流
   */
  publishLocalStream(localStream) {
    return new Promise((resolve, reject) => {
      this.client.publish(localStream).then(() => {
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   * 进入房间
   */
  joinRoom({ roomId }) {
    return new Promise((resolve, reject) => {
      this.client.join({ roomId }).then(() => {
        resolve({ type: true })
      }).catch(err => {
        resolve({ type: false, message: err })
      })
    })
  }

  /**
   * 订阅视频流
   */
  subscribe(stream) {
    this.client.subscribe(stream)
  }

  /**
   * 退出房间
   */
  leaveRoom() {
    return new Promise((resolve, reject) => {
      console.log(this.localStream)
      if (this.localStream !== null) {
        // // 关闭视频通话示例，对应addTrack接口的开启视频通话示例
        // const videoTrack = this.localStream.getVideoTrack()
        // if (videoTrack) {
        //     this.localStream.removeTrack(videoTrack).then(() => {
        //         // 关闭视频通话成功，停止videoTrack并释放摄像头资源
        //         console.log('remove video call success');
        //         videoTrack.stop();
        //     })
        // }
        this.client.unpublish(this.localStream).then(res => {
          this.localStream.close()
          this.client.leave().then(() => {
            resolve()
          }).catch(err => {
            reject(err)
          })
        }).catch((err) => {
          reject(err)
          console.log('error', 'unpublish error：' + err);
        })
      } else {
        this.client.leave().then(() => {
          resolve()
        }).catch(err => {
          reject(err)
        })
      }

    })
  }

  /**
   * 开启音频
   */
  muteAudio() {
    this.localStream.muteAudio()
  }

  /**
   * 关闭音频
   */
  unmuteAudio() {
    this.localStream.unmuteAudio()
  }

  /**
   * 开启视频
   */
  muteVideo() {
    this.localStream.muteVideo()
  }

  /**
   * 关闭视频
   */
  unmuteVideo() {
    this.localStream.unmuteVideo()
  }
}
