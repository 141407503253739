<template>
  <div id="app">
    <router-view
      v-loading="loading"
      :class="{ 'on-loading': loading }"
    ></router-view>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { sysConfigs } from "@/api";
export default {
  name: "App",
  computed: {
    ...mapState({
      loadingList: (state) => state.loadingList,
    }),
    loading: function () {
      return Object.keys(this.loadingList).length != 0;
    },
  },
  methods: {
    ...mapMutations({
      setSysConfig: "setSysConfig",
    }),
  },
  created: function () {
    if (location.search.indexOf("loginType=true") > 0) {
      this.$store.state.uniappLogin = true;
    }
    let data = {
      page: 1,
      pageSize: 999,
    };
    if (this.$store.state.uniappLogin) {
      data = {
        page: 1,
        pageSize: 999,
        merchant_code: "HK_GYT",
      };
    }
    sysConfigs(data).then((res) => {
      this.setSysConfig(res.data.dataList);
    });
    // 某些安卓机型似乎--safe-bottom/--safe-top取到的值没有单位。
    this.$nextTick(() => {
      let root = document.documentElement;
      let style = window.getComputedStyle(root);
      let safeBottom = style.getPropertyValue("--safe-bottom");
      let safeTop = style.getPropertyValue("--safe-top");
      if (!safeBottom.includes("px"))
        root.style.setProperty("--safeBottom", safeBottom + "px");
      if (!safeTop.includes("px"))
        root.style.setProperty("--safeTop", safeTop + "px");
      console.log(
        `当前safe-area-inset-bottom:${style.getPropertyValue(
          "--safe-bottom"
        )};\n当前safe-area-inset-top:${style.getPropertyValue("--safe-top")};`
      );
    });
  },
};
</script>
<style lang="scss">
body {
  margin: 0;
}
* {
  box-sizing: border-box;
}
#app {
  background-color: white;
  min-height: 100vh;
  color: $black;
}
.on-loading {
  height: 100vh;
  overflow: hidden;
}
@supports (--safe-top: constant(safe-area-inset-top)) {
  * {
    --safe-top: constant(safe-area-inset-top);
    --safe-bottom: constant(safe-area-inset-bottom);
  }
}
@supports (--safe-top: env(safe-area-inset-top)) {
  * {
    --safe-top: env(safe-area-inset-top);
    --safe-bottom: env(safe-area-inset-bottom);
  }
}
@supports (not (--safe-top: env(safe-area-inset-top))) and
  (not (--safe-top: constant(safe-area-inset-top))) {
  * {
    --safe-top: 0px;
    --safe-bottom: 0px;
  }
}
</style>