export default {
    namespaced: true,
    state: {
        questionList: [],
        selectedQuestion: {},
        showCommonQuestions: false,
        showQuestionDetail: false,
        directly: false
    },
    mutations: {
        setQuestionList(state, data) {
            state.questionList = data
        },
        setSelectedQuestion(state, data) {
            state.selectedQuestion = data
        },
        setShowCommonQuestions(state, data) {
            state.showCommonQuestions = data
        },
        setShowQuestionDetail(state, data) {
            if (!data && state.directly) {
                state.showCommonQuestions = false
                state.directly = false
                state.showQuestionDetail = false
            } else
                state.showQuestionDetail = data
        },
        openCertainQuestion(state, index) {
            state.selectedQuestion = state.questionList[index]
            state.showCommonQuestions = true
            state.showQuestionDetail = true
            state.directly = true
        }
    }
}