import { toFilePath } from "@/assets/js"
import { camel, underline } from "../../assets/js/varStyle";
import { updatePersonals, updPatientRecord } from "@/api";
export default {
    namespaced: true,
    state: {
        myProfileData: {
            id: "",
            avatar: "",
            name: "",
            gender: "",
            age: "",
            birthday: "",
            phone: "",
            idCardRegion: "",
            idCardNumber: "",
            address1: "",
            addressArea: "",
            addressRegion: "",
            addressProvince: "",
            emergencyContact: "",
            emergencyContactPhone: "",
            consignee:"",//收货人姓名
            consigneePhone:"",//收货人电话
        },
        patientProfileData: {
            id: "",
            name: "",
            gender: "",
            age: "",
            birthday: "",
            phone: "",
            idCardRegion: "",
            idCardNumber: "",
            address1: "",
            addressArea: "",
            addressRegion: "",
            addressProvince: "",
            emergencyContact: "",
            emergencyContactPhone: "",
            consignee:"",//收货人姓名
            consigneePhone:"",//收货人电话
        }
    },
    mutations: {
        setMyProfileData(state, data) {
            let parsedData = camel(data)
            for (let key of Object.keys(state.myProfileData)) {
                if (parsedData[key] != undefined)
                    state.myProfileData[key] = parsedData[key]
            }
        },
        setPatientProfileData(state, data) {
            let parsedData = camel(data)
            for (let key of Object.keys(state.patientProfileData)) {
                if (parsedData[key] != undefined)
                    state.patientProfileData[key] = parsedData[key]
            }
        }
    },
    actions: {
        updateMyProfile({ commit }, data) {
            return new Promise((resolve, reject) => {
                data.avatar = toFilePath(data.avatar)
                updatePersonals(data.id, underline(data))
                    .then(() => {
                        // commit("setMyProfileData", data);
                        resolve()
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        updatePatientProfile({ commit }, data) {
            return new Promise((resolve, reject) => {
                updPatientRecord(data.id, underline(data))
                    .then(() => {
                        // commit("setMyProfileData", data);
                        resolve()
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        }
    }
}