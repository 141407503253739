// 主页
const index = () => import('@/views/index/index.vue')
// 登入
// TODO: 現在的版本中沒有登錄/注冊的功能，之後可能會改爲登錄頁面
const login = () => import('@/views/index/login.vue')
// 预约
const reservation = () => import('@/views/reservation/reservation.vue')
const reservationComplete = () => import('@/views/reservation/complete.vue')
const reservationDetail = () => import('@/views/reservation/detail.vue')
// 档案
const profile = () => import('@/views/profile/profile.vue')
const myProfile = () => import('@/views/profile/myProfile.vue')
const patientRecords = () => import('@/views/profile/patientRecords.vue')
const patientDetail = () => import('@/views/profile/patientDetail.vue')
const paymentData = () => import('@/views/profile/paymentData.vue')
// 記錄
const caseRecords = () => import('@/views/caseRecords/caseRecords.vue')
const caseDetail = () => import('@/views/caseRecords/caseDetail.vue')
const caseDetailIndex = () => import('@/views/caseRecords/caseDetail/index.vue')
const caseRefund = () => import('@/views/caseRecords/caseDetail/refund.vue')
const casePayment = () => import('@/views/caseRecords/caseDetail/payment.vue')
// 問診室
const videoClinic = () => import('@/views/chatroom/videoClinic.vue')
const messageClinic = () => import('@/views/chatroom/messageClinic.vue')
// 医生端问诊室
const doctorVideoClinic = () => import('@/views/chatroom/doctorVideoClinic.vue')
const doctorMessageClinic = () => import('@/views/chatroom/doctorMessageClinic.vue')
// 用户问诊室-第三方用
const userVideoClinic = () => import('@/views/chatroom/userVideoClinic.vue')

//账单页面
const orderDetails = () => import('@/views/profile/orderDetails.vue')
//账单页面
const shieldingPager = () => import('@/views/index/shieldingPager.vue')
//隐私政策
const privacyPolicy = () => import('@/views/privacyPage/privacyPolicy.vue')
//条款与细则
const termsConditions = () => import('@/views/privacyPage/termsConditions.vue')

/**
 * / - 主页
 * /login - 登入页面
 * /register - 注册页面
 * /reservation - 预约页面
 * | - /complete - 预约完成页面
 * | - /detail - 預約細節頁面
 * | - /payment - 預約付款頁面（重新進入才會使用這個url）
 * /m-chatroom - 文字问诊室页面
 * | - /profile - 文字问诊室档案页面
 * /v-chatroom - 视频问诊室页面
 * | - /profile - 视频问诊室档案页面
 * | - /text-chat - 视频问诊室聊天页面
 * /case-records - 记录页面
 * | - /detail - 单个记录详细页面
 *     | - /refund - 单个记录退款
 *     | - /payment - 单个记录付款
 * /profile - 档案页面
 *  /myOrder - pdf 账单页面
 * | - /mine - 个人资料
 * | - /patient-records - 病人记录
 *     | - /detail - 病人记录详细
 * | - /payment-data - 付款资料
 */

export default [
    // 主页
    {
        path: '/',
        name: 'index',
        component: index
    },
    {
        path: '/user-not-found',
        name: 'login',
        component: login
    },
    // 预约页面
    {
        path: '/reservation',
        component: reservation,
        name: 'reservation',
    },
    {
        path: '/reservation/complete/:id',
        name: 'reservationComplete',
        component: reservationComplete
    },
    {
        path: '/reservation/detail/:id',
        name: 'reservationDetail',
        component: reservationDetail
    },
    {
        path: '/reservation/payment/:id',
        name: 'reservationPayment',
        component: reservation
    },
    // 档案页面
    {
        path: '/profile',
        name: 'profileIndex',
        component: profile
    },
    {
        path: '/profile/mine',
        name: 'myProfile',
        component: myProfile
    },
    // pdf账单
    {
        path: '/profile',
        name: 'orderDetails',
        component: orderDetails
    },
    //功能屏蔽後顯示正在開發頁面
    {
        path: '/index',
        name: 'shieldingPager',
        component: shieldingPager
    },
    //网页里面的隐私政策页面
    {
        path: '/privacyPage',
        name: 'privacyPolicy',
        component: privacyPolicy
    },
    //网页里面的条款与细则
    {
        path: '/privacyPage',
        name: 'termsConditions',
        component: termsConditions
    },
    
    // 参考任务PSMHJZ-72，移除历史病人相关的功能
    // {
    //     path: '/profile/patient-records',
    //     name: 'patientRecords',
    //     component: patientRecords
    // },
    // {
    //     path: '/profile/patient-records/detail/:id',
    //     name: 'patientDetail',
    //     component: patientDetail
    // },
    {
        path: '/payment-data',
        name: 'paymentData',
        component: paymentData
    },
    // 记录页面
    {
        path: '/case-records',
        name: 'caseRecords',
        component: caseRecords,
    },
    {
        path: '/case-records/detail',
        component: caseDetail,
        children: [
            {
                path: '/case-records/detail/:id',
                name: 'caseDetail',
                component: caseDetailIndex
            },
            {
                path: '/case-records/detail/:id/refund',
                name: 'caseRefund',
                component: caseRefund
            }, {
                path: '/case-records/detail/:id/payment',
                name: 'casePayment',
                component: casePayment
            }
        ]
    },
    // 問診室
    {
        path: '/video_clinic/:roomNumber',
        name: 'videoClinic',
        component: videoClinic
    },
    {
        path: '/msg_clinic/:roomNumber',
        name: 'messageClinic',
        component: messageClinic
    },
    // 医生端问诊室
    {
        path: '/doctor/video_clinic',
        name: 'doctorVideoClinic',
        component: doctorVideoClinic
    },
    {
        path: '/doctor/msg_clinic',
        name: 'doctorMessageClinic',
        component: doctorMessageClinic
    },
    {
        path: '/user/video_clinic/:roomNumber',
        name: 'userVideoClinic',
        component: userVideoClinic
    }
]