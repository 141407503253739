import { camel, underline } from "@/assets/js/varStyle";
import { patientCases, casesDetail } from "@/api";
export default {
    namespaced: true,
    state: {
        // 文字問診: text
        // 視像問診: video
        // 默認情況下是視像問診
        reservationType: "video",
        step: 1,
        // 1_attention.vue里面的agreement
        hasCheckedAgreement: false,
        // 8_shipmentAddrConfirm.vue里面的agreement
        hasCheckedConsigneeAgreement: false,
        // 9_bill.vue里面的agreement
        hasCheckedBillAgreement: false,
        selectedClinic: {
            id: "",
            name: "",
        },
        //进行查看是否是原生医生入口进来
        getIsDocComeIn: false,
        selectedDoctor: {
            id: "",
            name: "",
            specialist: "",
            avatar: "",
            fee: "",
            textFee: "",
            showFee: "",
            availableDateList: [],
            clinicName: "",
        },
        hasAppointmentData: false,
        appointmentData: {
            appointmentDate: "",
            appointmentTime: "",
        },
        hasPatientData: false,
        patientData: {
            id: "",
            birthday: "",
            name: "",
            gender: "",
            phone: "",
            emergencyContact: "",
            emergencyContactPhone: "",
            consignee: "",//收货人姓名
            consigneePhone: "",//收货人电话
            idCardRegion: "",
            idCardNumber: "",
            idCardPhoto: "",
            idCardPhotoF: "",
            promoCode: "",
            attachment: [],
        },
        hasAddressData: false,
        addressData: {
            address1: "",
            addressArea: "",
            addressProvince: "",
            addressRegion: "",
        },
        userData: {
            address1: "",
            addressArea: "",
            addressProvince: "",
            addressRegion: "",
            birthday: "",
            name: "",
            gender: "",
            phone: "",
            emergencyContact: "",
            emergencyContactPhone: "",
            idCardNumber: "",
            idCardPhoto: "",
            idCardPhotoF: "",
            idCardRegion: "",
            patientId: "",
            consignee: "",
            consigneePhone: "",
        },
        hasSymptom: false,
        symptomData: {
            selectedSymptom: [],
            symptomOther: "",
        },
        diseaseTimeId: "",
        hasSensitivitiesData: false,
        sensitivitiesData: {
            // isUseDrugs: false,
            // useDrugs: "",
            // isMaterialSensitivity: false,
            // materialSensitivity: "",
            drugSensitivityReply: [],
        },
        hasConsigneeData: false,
        consigneeData: {
            consigneeAddress1: "",
            consigneeAddressRegion: "",
            consigneeAddressArea: "",
            consigneeAddressProvince: "",
            consigneePhone: "",
            consignee: "",
        },
        reservationSuccessData: {
            id: "",
            consultationFee: "",
            bookingAmount: "",
            clinicId: -1,
            orderNumber: "",
            amount: "",
            promoCodeAmount: 0,
            isHaveCodeFee: "yes"
        },
        // 以下兩個變量分別表示【是否保存到個人資料】&【是否保存到病人資料】
        // 1表示是，0表示否
        //  2021年8月10日更新：移除病人资料功能，故默认改为0
        isSaveRecord: 0,
        isSavePersonal: 1,
        isFromApp: false,
    },
    mutations: {
        previous(state) {
            state.step = Math.max(1, state.step - 1);
        },
        next(state) {
            state.step = Math.min(10, state.step + 1);
        },
        next_clause(state) {
            state.step = 11;
        },
        next_privacy(state) {
            state.step = 12;
        },
        next_bill(state) {
            state.step = 9;
        },
        selectClinic(state, clinicData) {
            console.log(clinicData);
            state.selectedClinic.id = clinicData.id;
            state.selectedClinic.name = clinicData.name;
            state.selectedDoctor.id = "";
            state.selectedDoctor.name = "";
            state.selectedDoctor.showFee = "";
            state.selectedDoctor.specialist = "";
            state.selectedDoctor.gender = 3;
            state.selectedDoctor.avatar = "";
            state.selectedDoctor.clinicName = "";
            state.selectedDoctor.availableDateList = [];
        },
        selectDoctor(state, doctorData) {
            state.selectedClinic.id = doctorData.clinicId;
            state.selectedClinic.name = doctorData.name;


            let parsedData = camel(doctorData);
            let getFee = parsedData.fee;
            if (getFee != undefined) {
                // for (let key of Object.keys(state.selectedDoctor)) {
                //     if (Object.keys(parsedData).includes(key)) {
                //         state.selectedDoctor[key] = parsedData[key];
                //     }
                // }
                state.selectedDoctor.gender = parsedData.gender;
                state.selectedDoctor.id = parsedData.id;
                state.selectedDoctor.name = parsedData.name;
                state.selectedDoctor.fee = parsedData.fee;
                state.selectedDoctor.textFee = parsedData.textfee;
                state.selectedDoctor.specialist = parsedData.specialist;
                state.selectedDoctor.avatar = parsedData.avatar;
                state.selectedDoctor.availableDateList = parsedData.availableDateList;
                state.selectedDoctor.clinicName = parsedData.clinicName;

                if (state.reservationType == "video") {
                    state.selectedDoctor.showFee = parsedData.fee;
                } else {
                    state.selectedDoctor.showFee = parsedData.textfee;
                }
            } else {
                state.selectedDoctor.gender = parsedData.gender;
                state.selectedDoctor.id = parsedData.id;
                state.selectedDoctor.name = parsedData.name;
                state.selectedDoctor.fee = parsedData.consultationFee;
                state.selectedDoctor.textFee = parsedData.textConsultationFee;
                state.selectedDoctor.specialist = parsedData.specialist;
                state.selectedDoctor.avatar = parsedData.avatar;
                state.selectedDoctor.availableDateList = parsedData.availableDateList;
                state.selectedDoctor.clinicName = parsedData.clinic_name;
                if (state.reservationType == "video") {
                    state.selectedDoctor.showFee = "$ " + parseFloat(parsedData.consultationFee).toFixed(2);
                } else {
                    state.selectedDoctor.showFee = "$ " + parseFloat(parsedData.textConsultationFee).toFixed(2);
                }
                state.getIsDocComeIn = true;
            }
        },


        setAppointmentData(state, appointmentData) {
            state.appointmentData.appointmentDate =
                appointmentData.appointmentDate;
            state.appointmentData.appointmentTime =
                appointmentData.appointmentTime;
            state.hasAppointmentData = true;
        },
        // 1_attention.vue里面的agreement
        checkAgreement(state) {
            state.hasCheckedAgreement = true;
        },
        clearReservationData(state) {
            for (let key of Object.keys(state)) {
                if (key.slice(0, 3) === "has") {
                    // 将所有has开头的值设置为false
                    // 如：hasCheckedAgreement
                    state[key] = false;
                } else if (typeof state[key] === "object") {
                    // 如果是object，则遍历内部的属性
                    let obj = state[key];
                    for (let attr of Object.keys(obj)) {
                        // 除了几个特殊的需要单独设置之外，其他都是空字符串
                        if (
                            [
                                "availableDateList",
                                "selectedSymptom",
                                "drugSensitivityReply",
                                "attachment",
                            ].includes(attr)
                        ) {
                            obj[attr] = [];
                        } else if (["clinicId"].includes(attr)) {
                            obj[attr] = -1;
                        } else if (
                            ["isSaveRecord", "isSavePersonal"].includes(attr)
                        ) {
                            obj[attr] = 1;
                        } else if (["isFromApp"].includes(attr)) {
                            obj[attr] = false;
                        } else {
                            obj[attr] = "";
                        }
                    }
                } else {
                    // 最后剩下三个
                    switch (key) {
                        case "reservationType":
                            state[key] = "video";
                            break;
                        case "step":
                            state[key] = 1;
                            break;
                        case "diseaseTimeId":
                            state[key] = "";
                            break;
                    }
                }
            }
        },
        setReservationType(state, type) {
            state.reservationType = type;
        },
        setUserData(state, data) {
            let parsedData = camel(data);
            for (let key of Object.keys(state.userData)) {
                if (Object.keys(parsedData).includes(key))
                    state.userData[key] = parsedData[key];
            }
        },
        setPatientData(state, data) {
            let parsedData = camel(data);
            for (let key of Object.keys(state.patientData)) {
                if (Object.keys(parsedData).includes(key))
                    state.patientData[key] = parsedData[key];
            }
        },
        setSaveData(state, data) {
            state.isSaveRecord = data.isSaveRecord;
            state.isSavePersonal = data.isSavePersonal;
        },
        confirmHasPatientData(state) {
            state.hasPatientData = true;
        },
        setAddressData(state, data) {
            let parsedData = camel(data);
            for (let key of Object.keys(state.addressData)) {
                if (Object.keys(parsedData).includes(key))
                    state.addressData[key] = parsedData[key];
            }
        },
        confirmHasAddressData(state) {
            state.hasAddressData = true;
        },
        setSymptomData(state, data) {
            state.symptomData.selectedSymptom = data.selectedSymptom;
            state.symptomData.symptomOther = data.symptomOther;
            state.hasSymptom = true;
        },
        setDiseaseTime(state, id) {
            state.diseaseTimeId = id;
        },
        setSensitivitiesData(state, data) {
            for (let key of Object.keys(state.sensitivitiesData)) {
                state.sensitivitiesData[key] = data[key];
            }
            state.hasSensitivitiesData = true;
        },
        setConsigneeData(state, data) {
            for (let key of Object.keys(state.consigneeData)) {
                let parsedKey = key.replace(/consignee(.)/, (match, p1) => {
                    return p1.toLowerCase();
                });
                state.consigneeData[key] = data[parsedKey];
            }
            state.consigneeData.consignee = data.contact;
            state.hasConsigneeData = true;
        },
        // 8_shipmentAddrConfirm.vue里面的agreement
        checkConsigneeAgreement(state) {
            state.hasCheckedConsigneeAgreement = true;
        },
        setReservationSuccessData(state, data) {
            let parsedData = camel(data);
            state.reservationSuccessData.promoCodeAmount = parsedData.promoCodeAmount
            state.reservationSuccessData.consultationFee =
                parsedData.consultationFee;
            state.reservationSuccessData.bookingAmount =
                parsedData.bookingAmount;
            state.reservationSuccessData.clinicId = parsedData.clinicId;
            state.reservationSuccessData.id = parsedData.id;
            // typeId:
            // 1 = 问诊预约费
            // 2 = 问诊账单
            parsedData.orderList.forEach((e) => {
                if (e.typeId === 1) {
                    state.reservationSuccessData.orderNumber = e.orderNumber;
                    state.reservationSuccessData.amount = e.amount;
                }
            });
        },
        // 9_bill.vue里面的agreement
        checkBillAgreement(state) {
            state.hasCheckedBillAgreement = true;
        },
        setSteps(state, step) {
            state.step = step;
        },
        checkFromApp(state) {
            state.isFromApp = true;
        },
    },
    actions: {
        createCase({ commit, state }) {
            let data = {
                typeId: state.reservationType === "video" ? 1 : 2,
                clinicId: state.selectedClinic.id,
                doctorId: state.selectedDoctor.id,
                isSavePersonal: state.isSavePersonal,
                isSaveRecord: state.isSaveRecord,
            };

            Object.assign(data, state.appointmentData);

            Object.assign(data, state.patientData);

            Object.assign(data, state.addressData);

            data.symptomIds = state.symptomData.selectedSymptom.join(",");
            data.symptomOther = state.symptomData.symptomOther;

            data.diseaseTimeId = state.diseaseTimeId;

            Object.assign(data, state.sensitivitiesData);

            Object.assign(data, state.consigneeData);

            return new Promise((resolve, reject) => {
                patientCases(underline(data))
                    .then((res) => {
                        commit("setReservationSuccessData", res.data);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        goToPaymentPage({ commit, state }, caseId) {
            casesDetail(caseId).then((res) => {
                commit("setReservationSuccessData", res.data);
                commit("selectDoctor", res.data.doctor);
                commit("setSteps", 9);
            });
        },
    },
};
