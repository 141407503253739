import Axios from "axios";
import qs from "qs";
import store from "@/store";
import { isSafari } from "@/assets/js";

const axios = Axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? "/dev-api"
            : process.env.VUE_APP_REQUEST_BASE_URL,
    params: {},
});

const formDataRequest = [
    "patient-personals",
    "patient-personals/upd",
    "files/upload-file",
];
const formatConfig = (config) => {
    if (config.params === undefined) config.params = {};
    const { method, url } = config;
    if (
        method !== "get" &&
        method !== "put" &&
        method !== "delete" &&
        !formDataRequest.includes(url)
    ) {
        config.data = qs.stringify(config.data);
    }

    const token = store.state.user.token;
    if (token && url != "imeddy-api-members/login")
        config.params.access_token = token;
    return config;
};

// 請求攔截器
axios.interceptors.request.use(
    (config) => {
        if (!config.isPooling) {
            let timestamp = new Date().getTime();
            config.timestamp = timestamp;
            // Vue.set(store.state.loadingList, timestamp, true);
        }
        return formatConfig(config);
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    (response) => {

        let isSa = isSafari();
        const code =
            parseInt(response.data.rtn) || parseInt(response.data.code);
        if (code !== 200) {
            if (code === 401) {
                window.uni.postMessage({
                    data: {
                        action: "update_page",
                    },
                });
                if (window.webBackToAndroid) {
                    window.webBackToAndroid.onBack();
                }
                if (window.webkit) {
                    window.webkit.messageHandlers.getDataFromWeb.postMessage("close");
                }

                if (isSa) {
                    window.location.href = "iMeddy://com.imeddy.client?close=1";
                }
            }
            return Promise.reject(response.data);
        } else {
            return response.data;
        }
    },
    (error) => {
        console.error(error);
        if (error.response && error.response.status === 500) {
            error.message = "服务器请求失败，请稍后再试！";
        }

        return Promise.reject(error);
    }
);

export default axios;
